.todayContainer {
  grid-column: 1;
}

.todaySunList {
  padding: 0;
}

.todaySunListItem {
  list-style-type: none;
  border-top: 1px solid var(--grey2);
  border-bottom: 1px solid var(--grey2);

  a {
    display: grid;
    color: var(--waterBlue);
    font-weight: 500;

    svg {
      grid-column: 2/3;
      grid-row: 1/3;
      align-self: center;
      margin-left: auto;
      width: 6px;
      fill: var(--landGreen);
    }
  }
}

.todaySun {
  padding: 10px 0;
  margin: 0;

  &[data-type='sunset'] {
    grid-column: 1/2;
  }

  img {
    margin-right: 12px;
  }

  figcaption {
    display: inline-block;
  }
}
