@use 'sass:map';
@use 'styles/variables.module.scss' as v;

.MyWeatherContainer {
	width: 100vw;
	overflow: hidden;
	padding: 0 16px;
	margin-bottom: 15px;


	@media #{map.get(v.$media, "tablet")} {
		display: grid;
		grid-auto-columns: 1fr 1fr;
		grid-template-rows: 55px auto;
	}

	@media #{map.get(v.$media, "desktopS")} {
		display: block;
		grid-auto-columns: initial;
		grid-template-rows: initial;
		width: 100%;
		height: 100%;
		margin-bottom: 0px;
	}
}

.MyWeatherTitle {
	border-bottom: 1px solid var(--grey);
	padding-bottom: 10px;
	margin: 10px 0 15px;

	@media #{map.get(v.$media, "tablet")} {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	@media #{map.get(v.$media, "desktopS")} {
		display: none;
	}
}

.myWeatherAdSlot {
	@media #{map.get(v.$media, "tablet")} and #{map.get(v.$media, "desktopSMax")} {
		grid-column: 2 / 3;
		grid-row: 1 / -1;
		margin-top: 10px;
	}
}

.myWeatherHeading {
	font-size: 1.6rem;
	margin-bottom: 10px;
}
.myWeatherList {
	list-style-type: none;
	padding: 0;
	margin: 0 0 10px;
}
