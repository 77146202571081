.graph {
  width: 100%;
  height: auto;
}

.graphText {
  fill: var(--grey3);
  font: bold 1.2rem RTLGraphik, Arial, Helvetica, sans-serif;
}

.graphTimeLabel {
  fill: var(--waterBlue);
  font: 1.3rem RTLGraphik, Arial, Helvetica, sans-serif;
}

.nowLabel {
  fill: var(--waterBlue);
  font: bold 1.3rem RTLGraphik, Arial, Helvetica, sans-serif;
}
