.relevantItem {
  list-style-type: none;
  padding: 12px 0;
  border-bottom: 1px solid var(--grey2);

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--waterBlue);
    font-weight: 500;
  }

  svg {
    margin-left: auto;
    width: 6px;
    fill: var(--landGreen);
  }
}

.relevantValue {
  display: inline-block;
  border-radius: 2px;
  width: 40px;
  height: 25px;
  text-align: center;
  margin-right: 15px;
  line-height: 25px;
  color: var(--white);

  /* (Max) Temperature */
  &.temperature38 {
    background-color: #970033;
  }

  &.temperature37 {
    background-color: #b10022;
  }

  &.temperature36 {
    background-color: #b90025;
  }

  &.temperature35 {
    background-color: #c80026;
  }

  &.temperature34 {
    background-color: #d8002a;
  }

  &.temperature33 {
    background-color: #ed002f;
  }

  &.temperature32 {
    background-color: #fc003e;
  }

  &.temperature31 {
    background-color: #dd0004;
  }

  &.temperature30 {
    background-color: #e00004;
  }

  &.temperature29 {
    background-color: #e90916;
  }

  &.temperature28 {
    background-color: #ed1e31;
  }

  &.temperature27 {
    background-color: #ee4831;
  }

  &.temperature26 {
    background-color: #fc4a06;
  }

  &.temperature25 {
    background-color: #fd6007;
  }

  &.temperature24 {
    background-color: #fd7707;
  }

  &.temperature23 {
    background-color: #fd8e07;
  }

  &.temperature22 {
    background-color: #fda207;
  }

  &.temperature21 {
    background-color: #feb908;
  }

  &.temperature20 {
    background-color: #fed809;
  }

  &.temperature19 {
    background-color: #ffee0a;
    color: inherit;
  }

  &.temperature18 {
    background-color: #ffff0c;
    color: inherit;
  }

  &.temperature17 {
    background-color: #e8f909;
    color: inherit;
  }

  &.temperature16 {
    background-color: #cfef08;
    color: inherit;
  }

  &.temperature15 {
    background-color: #b8e609;
  }

  &.temperature14 {
    background-color: #a0da08;
  }

  &.temperature13 {
    background-color: #89d007;
  }

  &.temperature12 {
    background-color: #76c809;
  }

  &.temperature11 {
    background-color: #50bf06;
  }

  &.temperature10 {
    background-color: #209404;
  }

  &.temperature9 {
    background-color: #1c9f05;
  }

  &.temperature8 {
    background-color: #1cae05;
  }

  &.temperature7 {
    background-color: #21c306;
  }

  &.temperature6 {
    background-color: #26dc06;
  }

  &.temperature5 {
    background-color: #29eb07;
  }

  &.temperature4 {
    background-color: #35ff1b;
  }

  &.temperature3 {
    background-color: #44ff33;
  }

  &.temperature2 {
    background-color: #6cff64;
    color: inherit;
  }

  &.temperature1 {
    background-color: #9aff96;
    color: inherit;
  }

  &.temperature0 {
    background-color: #99fbd0;
    color: inherit;
  }

  &.temperaturecold1 {
    background-color: #98f7ff;
    color: inherit;
  }

  &.temperaturecold2 {
    background-color: #6effff;
    color: inherit;
  }

  &.temperaturecold3 {
    background-color: #41f2f0;
  }

  &.temperaturecold4 {
    background-color: #32ddf8;
  }

  &.temperaturecold5 {
    background-color: #26c8ff;
  }

  &.temperaturecold6 {
    background-color: #1aacfe;
  }

  &.temperaturecold7 {
    background-color: #1493fe;
  }

  &.temperaturecold8 {
    background-color: #0e79fe;
  }

  &.temperaturecold9 {
    background-color: #1169e0;
  }

  &.temperaturecold10 {
    background-color: #1996d1;
  }

  &.temperaturecold11 {
    background-color: #1084cd;
  }

  &.temperaturecold12 {
    background-color: #0c72c5;
  }

  &.temperaturecold13 {
    background-color: #0963b8;
  }

  &.temperaturecold14 {
    background-color: #075aab;
  }

  &.temperaturecold15 {
    background-color: #054fa3;
  }

  &.temperaturecold16 {
    background-color: #6242ff;
  }

  &.temperaturecold17 {
    background-color: #6263ff;
  }

  &.temperaturecold18 {
    background-color: #747aff;
  }

  &.temperaturecold19 {
    background-color: #8f97ff;
  }

  &.temperaturecold20 {
    background-color: #a4abfa;
  }

  &.temperaturecold21 {
    background-color: #a66cbd;
  }

  &.temperaturecold22 {
    background-color: #b400a1;
  }

  &.temperaturecold23 {
    background-color: #a500ad;
  }

  &.temperaturecold24 {
    background-color: #7900ad;
  }

  &.temperaturecold25 {
    background-color: #7d0bc7;
  }

  /* Pollen */
  &.pollen0 {
    background-color: #49da21;
  }

  &.pollen1 {
    background-color: #49da21;
  }

  &.pollen2 {
    background-color: #30d200;
  }

  &.pollen3 {
    background-color: #fff88b;
    color: inherit;
  }

  &.pollen4 {
    background-color: #fff642;
    color: inherit;
  }

  &.pollen5 {
    background-color: #fdbb31;
  }

  &.pollen6 {
    background-color: #fd8e24;
  }

  &.pollen7 {
    background-color: #fc103e;
  }

  &.pollen8 {
    background-color: #960a33;
  }

  &.pollen9 {
    background-color: #a66dbc;
  }

  &.pollen10 {
    background-color: #b330a1;
  }

  /* uv index */
  &.uvindex0 {
    background-color: #49da21;
  }

  &.uvindex1 {
    background-color: #49da21;
  }

  &.uvindex2 {
    background-color: #30d200;
  }

  &.uvindex3 {
    background-color: #fff88b;
    color: inherit;
  }

  &.uvindex4 {
    background-color: #fff642;
    color: inherit;
  }

  &.uvindex5 {
    background-color: #fdbb31;
  }

  &.uvindex6 {
    background-color: #fd8e24;
  }

  &.uvindex7 {
    background-color: #fc103e;
  }

  &.uvindex8 {
    background-color: #960a33;
  }

  &.uvindex9 {
    background-color: #a66dbc;
  }

  &.uvindex10,
  &.uvindex11,
  &.uvindex12,
  &.uvindex13,
  &.uvindex14,
  &.uvindex15 {
    background-color: #b330a1;
  }
  /* BBQ */
  &.bbqradar1 {
    background-color: #a0d4d1;
  }

  &.bbqradar2 {
    background-color: #9dcea5;
  }

  &.bbqradar3 {
    background-color: #e3d255;
  }

  &.bbqradar4 {
    background-color: #f18c33;
  }

  &.bbqradar5 {
    background-color: #ea5932;
  }

  &.bbqradar6 {
    background-color: #d63332;
  }

  &.bbqradar7 {
    background-color: #c8282f;
  }

  &.bbqradar8 {
    background-color: #ae2229;
  }

  &.bbqradar9 {
    background-color: #921921;
  }

  &.bbqradar10 {
    background-color: #5c0d13;
  }

  /* Weerkaarten - Wind */
  &.bft12 {
    background-color: #ff0000;
  }

  &.bft11 {
    background-color: #ff2c00;
  }

  &.bft10 {
    background-color: #ff5900;
  }

  &.bft9 {
    background-color: #ff8500;
  }

  &.bft8 {
    background-color: #ffae2f;
  }

  &.bft7 {
    background-color: #ffd65d;
  }

  &.bft6 {
    background-color: #ffff8c;
    color: inherit;
  }

  &.bft5 {
    background-color: #30d200;
  }

  &.bft4 {
    background-color: #49da21;
  }

  &.bft3 {
    background-color: #63e143;
  }

  &.bft2 {
    background-color: #7dea64;
  }

  &.bft1 {
    background-color: #95f085;
  }

  /* Visibility */
  &.visibility-low {
    background-color: #c8282f;
  }
}
